
import { computed, defineComponent, reactive, ref, watch } from '@vue/composition-api'
import { mdiMagnify, mdiPlus, mdiFilter, mdiChevronUp, mdiChevronDown, mdiArrowLeftThin } from '@mdi/js'
import { isEqual } from 'lodash-es'

import CommonTableView from '@/components/common/CommonTableView.vue'
import CommonExportMenu from '@/components/common/CommonExportMenu.vue'

import { useNoteDialog } from '@/composables/useNoteDialog'
import { useTaskDialog } from '@/composables/useTaskDialog'
import { useFilter } from '@/composables/useFilter'

import { dateDotNotationToDash } from '@/utils/convertDate'
import { handleError } from '@/utils/handleError'
import { setSort } from '@/utils/manipulateTableSort'
import { hasSufficientRights } from '@/utils/hasSufficientRights'

import { useDeleteTendering, useGetTenderings, useExportTenderings, useGetTenderingsBasic } from '@/api/tendering'
import { useGetOrgUnitsBasic } from '@/api/orgUnit'
import { useGetEnumItemsBasic } from '@/api/enumItem'
import { useGetProfilesBasic } from '@/api/profile'

import { TenderingOutput } from '@/api/types/tendering'
import { FilterDataTypes, FilterField, OptionalFiltersParam } from '@/composables/types/useFilter'
import { FILTER_FIELD_KEY } from '@/views/acquisition/views/tenderings/types'
import { Rights } from '@/api/types/right'
import { useNotify } from '@/store'
import { useGetPositionsBasic } from '@/api/position'
import { PROFILE_TYPE } from '@/api/types/profile'
import { SOURCING_TYPE } from '@/views/planning/views/workforcePlanning/types'

export default defineComponent({
  name: 'Tenderings',
  components: {
    CommonTableView,
    CommonExportMenu,
    AddEditTenderingDialog: () =>
      import('@/views/acquisition/views/tenderings/components/AddEditTenderingDialog.vue'),
    CommonDeleteDialog: () => import('@/components/common/CommonDeleteDialog.vue'),
    CommonInfoDialog: () => import('@/components/common/CommonInfoDialog.vue'),
    CommonTasksDialog: () => import('@/components/common/CommonTasksDialog.vue'),
    CommonNotesDialog: () => import('@/components/common/CommonNotesDialog.vue'),
    TenderingsFilterBar: () => import('@/views/acquisition/views/tenderings/components/TenderingsFilterBar.vue'),
  },
  setup: (_, { root }) => {
    const { addNotification } = useNotify()

    const isForwardedFromTimeline = ref(Boolean(root.$route.params.ids))

    const TABLE_HEADERS = [
      {
        text: root.$t('contractData.tenderings.table.col.title.id'),
        value: 'id',
        sortable: false,
      },
      {
        text: root.$t('contractData.tenderings.table.col.title.positionId'),
        value: 'position.id',
        sortable: false,
      },
      {
        text: root.$t('contractData.tenderings.table.col.title.sourcing'),
        value: 'sourcing',
        sortable: false,
      },
      {
        text: root.$t('contractData.tenderings.table.col.title.demand'),
        value: 'demand.name',
        sortable: false,
      },
      {
        text: root.$t('contractData.tenderings.table.col.title.profile'),
        value: 'profile.name',
        sortable: false,
      },
      {
        text: root.$t('contractData.tenderings.table.col.title.totalCount'),
        value: 'totalCount',
        sortable: true,
      },
      {
        text: root.$t('contractData.tenderings.table.col.title.remainingCount'),
        value: 'remainingCount',
        sortable: false,
      },
      {
        text: root.$t('contractData.tenderings.table.col.title.deadline'),
        value: 'deadline',
        sortable: true,
      },
      {
        text: root.$t('contractData.tenderings.table.col.title.status'),
        value: 'status.name',
        sortable: false,
      },
      {
        text: root.$t('contractData.tenderings.table.col.title.tasksCount'),
        value: 'tasksCount',
        sortable: false,
      },
      {
        text: root.$t('contractData.tenderings.table.col.title.notesCount'),
        value: 'notesCount',
        sortable: false,
      },
      {
        text: '',
        value: 'actions',
        align: 'right',
        sortable: false,
      },
    ]

    const PROPERTIES_TO_SHOW_IN_ROW_INFO_DIALOG: (keyof TenderingOutput)[] = [
      'createdBy',
      'createdAt',
      'updatedBy',
      'updatedAt',
      'demand',
    ]

    const isAddEditTenderingDialogOpen = ref(false)

    const isEditMode = ref(false)

    const activeTendering = ref<TenderingOutput | null>(null)

    function onClickEdit(_, { item: tendering }: { item: TenderingOutput }): void {
      if (hasSufficientRights(Rights.TENDERING_UPDATE) && hasSufficientRights(Rights.BASIC_READ)) {
        isEditMode.value = true

        isAddEditTenderingDialogOpen.value = true

        activeTendering.value = { ...tendering }
      }
    }

    const isDeleteTenderingDialogOpen = ref(false)

    async function onClickDelete(tendering: TenderingOutput): Promise<void> {
      activeTendering.value = { ...tendering }

      isDeleteTenderingDialogOpen.value = true
    }

    const { deleteTendering } = useDeleteTendering()

    async function onDeleteTendering(): Promise<void> {
      try {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        await deleteTendering(activeTendering.value!.id)
      } catch (error: unknown) {
        handleError(error)
      }

      isDeleteTenderingDialogOpen.value = false

      activeTendering.value = null

      debouncedCb.value()
    }

    const isRowInfoDialogOpen = ref(false)

    function onClickInfo(tendering: TenderingOutput): void {
      activeTendering.value = { ...tendering }

      isRowInfoDialogOpen.value = true
    }

    function onCloseAddEditDeleteInfoDialog(): void {
      isAddEditTenderingDialogOpen.value = false

      isDeleteTenderingDialogOpen.value = false

      isRowInfoDialogOpen.value = false

      isEditMode.value = false

      activeTendering.value = null
    }

    const { exec: getOrgUnitsBasic, data: orgUnits, isLoading: isLoadingOrgUnitsBasic } = useGetOrgUnitsBasic()

    const {
      exec: getEnumItemsBasic,
      data: enumItemsBasic,
      isLoading: isLoadingEnumItemsBasic,
    } = useGetEnumItemsBasic()

    const { exec: getProfilesBasic, data: profilesBasic, isLoading: isLoadingProfilesBasic } = useGetProfilesBasic()

    const {
      exec: getTenderingsBasic,
      data: tenderingsBasic,
      isLoading: isLoadingTenderingsBasic,
    } = useGetTenderingsBasic()

    const {
      exec: getPositionsBasic,
      data: positionsBasic,
      isLoading: isLoadingPositionsBasic,
    } = useGetPositionsBasic()
    const isFilterDropdownOpen = ref(false)

    function onToggleFilterDropdown() {
      if (!isFilterDropdownOpen.value) {
        isFilterDropdownOpen.value = true

        if (
          !enumItemsBasic.value?.length &&
          !orgUnits.value?.length &&
          !profilesBasic.value?.length &&
          !positionsBasic.value?.length &&
          !tenderingsBasic.value?.length
        ) {
          getEnumItemsBasic({ params: { enumItemAssignment: 'TENDERING_STATUS' } })
          getOrgUnitsBasic()
          getProfilesBasic({ params: { type: PROFILE_TYPE.TENDERING } })
          getPositionsBasic({ params: { positionStatuses: 'ACTIVE', approved: true } })
          getTenderingsBasic()
        }
      } else {
        isFilterDropdownOpen.value = false
      }
    }

    const FILTER_FIELDS = ref<FilterField[]>([
      {
        key: FILTER_FIELD_KEY.TenderingIds,
        value: null,
        label: root.$t('acquisition.tenderings.filters.tenderingIds'),
        items: computed(() => tenderingsBasic.value ?? []),
        isLoading: computed(() => isLoadingTenderingsBasic.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.StatusIds,
        value: null,
        label: root.$t('acquisition.tenderings.filters.status'),
        items: computed(() => enumItemsBasic.value ?? []),
        isLoading: computed(() => isLoadingEnumItemsBasic.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.OrganizationalUnitIds,
        value: null,
        label: root.$t('acquisition.tenderings.filters.organizationalUnit'),
        items: computed(() => orgUnits.value ?? []),
        isLoading: computed(() => isLoadingOrgUnitsBasic.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.DeadlineEndFrom,
        value: null,
        label: root.$t('acquisition.tenderings.filters.deadlineEndFrom'),
        items: [],
        dataTyp: FilterDataTypes.Date,
      },
      {
        key: FILTER_FIELD_KEY.DeadlineEndTo,
        value: null,
        label: root.$t('acquisition.tenderings.filters.deadlineEndTo'),
        items: [],
        dataTyp: FilterDataTypes.Date,
      },
      {
        key: FILTER_FIELD_KEY.ProfileIds,
        value: null,
        label: root.$t('acquisition.tenderings.filters.profile'),
        items: computed(() => profilesBasic.value ?? []),
        isLoading: computed(() => isLoadingProfilesBasic.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.PositionIds,
        value: null,
        label: root.$t('acquisition.tenderings.filters.position'),
        items: computed(() => positionsBasic.value ?? []),
        isLoading: computed(() => isLoadingPositionsBasic.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.Sourcing,
        value: null,
        label: root.$t('planning.workforcePlanning.filters.sourcingTypes'),
        items: computed(() => SOURCING_TYPE ?? []),
        dataTyp: FilterDataTypes.ArrayString,
      },
    ])

    const { debouncedCb, vuetifyTableOptions, paginationParams, filterFieldsObject, isInit } = useFilter(
      FILTER_FIELDS.value as FilterField[],
      init
    )

    watch(
      filterFieldsObject,
      (newVal, oldVal) => {
        isInit.value = false

        if (!isEqual(newVal, oldVal)) {
          debouncedCb.value()
          vuetifyTableOptions.value.page = 1
        }
      },
      { deep: true }
    )

    setSort(vuetifyTableOptions, { by: 'deadline', desc: true })

    const {
      exec: getTenderings,
      data: tenderings,
      isLoading: isLoadingTenderings,
      paginationResponse,
    } = useGetTenderings()

    const totalTenderings = computed(() => paginationResponse.value.totalElements)

    async function init(filterFieldsObject?: OptionalFiltersParam): Promise<void> {
      await getTenderings({ params: { ...paginationParams.value, ...filterFieldsObject } })
    }

    const { exportTenderings, isLoading: isLoadingExport } = useExportTenderings()

    async function onExportTenderings(dateRange: { startMonth: string; endMonth: string }) {
      try {
        await exportTenderings({
          startMonth: dateDotNotationToDash(dateRange.startMonth),
          endMonth: dateDotNotationToDash(dateRange.endMonth),
        })

        addNotification({
          text: root.$t('misc.export.actions.success') as string,
          timeout: 5000,
          type: 'success',
        })
      } catch (error: unknown) {
        handleError(error)
      }
    }

    const {
      isOpen: isNotesDialogOpen,
      notes: tenderingNotes,
      onClickOpenDialog: onOpenNotesDialog,
      onReload: onReloadNotes,
      entityId: notesEntityId,
    } = useNoteDialog<TenderingOutput>(tenderings, () => init(filterFieldsObject.value))

    const {
      isOpen: isTasksDialogOpen,
      tasks: tenderingTasks,
      onClickOpenDialog: onOpenTasksDialog,
      onReload: onReloadTasks,
      entityId: tasksEntityId,
    } = useTaskDialog<TenderingOutput>(tenderings, () => init(filterFieldsObject.value))

    function onClickBack(): void {
      root.$router.push({
        name: 'planning-position-timeline',
      })
    }

    return reactive({
      icons: {
        mdiMagnify,
        mdiPlus,
        mdiFilter,
        mdiChevronUp,
        mdiChevronDown,
        mdiArrowLeftThin,
      },
      constants: {
        Rights,

        TABLE_HEADERS,

        PROPERTIES_TO_SHOW_IN_ROW_INFO_DIALOG,

        FILTER_FIELDS,
      },
      state: {
        tenderings,
        isLoadingTenderings,

        isAddEditTenderingDialogOpen,
        isEditMode,

        activeTendering,

        isDeleteTenderingDialogOpen,

        isRowInfoDialogOpen,

        isNotesDialogOpen,
        tenderingNotes,
        notesEntityId,

        isTasksDialogOpen,
        tenderingTasks,
        tasksEntityId,

        isFilterDropdownOpen,

        vuetifyTableOptions,
        totalTenderings,

        isLoadingExport,

        isForwardedFromTimeline,
      },
      listeners: {
        onClickEdit,

        onClickDelete,
        onDeleteTendering,

        onExportTenderings,

        onClickInfo,

        onCloseAddEditDeleteInfoDialog,

        onOpenNotesDialog,
        onReloadNotes,

        onOpenTasksDialog,
        onReloadTasks,

        onToggleFilterDropdown,

        onClickBack,
      },
      functions: {
        debouncedCb,
        hasSufficientRights,
      },
    })
  },
})
